<template>
  <div>
    <!-- :rules="[filedRules.required]"  -->
    <v-text-field label="원장님성함" type="text" ref="refNameKr" v-model="targetModel.directorName" />
    <v-text-field label="원장님성함" type="text" ref="refNameEn" v-model="targetModel.directorName" />
    <v-text-field label="원장님성함" type="text" ref="refNameCn" v-model="targetModel.directorName" />
  </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>