<template>
  <div class="setAlign">
    <v-text-field
      ref="refWeight"
      v-model="inputValue"
      label=""
      placeholder="* Last Updated Value"
      @change="changeInputValue"
    />
    <SelectBox
      :value="select"
      :items="selectList"
      @changeSelect="updateSelectHandler"
    />
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
export default {
  components: {
    SelectBox,
  },
  props: {
    selectedValue: {
      type: [Number, String],
      default: "",
    },
    selectList: {
      type: [Array, Object],
    },
    inputValuePros: {
      type: [Number, String],
      default: "",
    },
  },
  watch: {
    selectedValue(val) {
      this.select = this.selectedValue;
    },
    inputValuePros(val) {
      this.inputValue = this.inputValuePros;
    },
  },
  data() {
    return {
      inputValue: this.inputValuePros,
      select: this.selectedValue,
    };
  },
  methods: {
    updateSelectHandler(e) {
      this.select = e;
      this.$emit("updateSelectHandler", e);
    },
    changeInputValue(e) {
      this.$emit("changeInputValue", e);
    },
  },
};
</script>
<style lang="scss">
.setAlign {
  display: flex;
  flex-direction: row;
}
</style>