<template>
  <v-card>
    <v-tabs v-model="tabs">
      <v-tab> Favorites </v-tab>
      <v-tab> Nearby </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item :transition="false">
        <v-card flat>
          <v-card-text><p>hey</p></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false">
        <v-card flat>
          <v-card-text> <p>hey2</p></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      tabs: 0,
    };
  },
};
</script>
<style lang="">
</style>