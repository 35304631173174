<template>
  <div class="_box">
    <SelectBox
      :label="label"
      ref="refType"
      :value="type"
      :items="items"
      @changeSelect="typeListHandler"
    />
    <v-text-field
      v-if="type === 'etc'"
      type="text"
      v-model="typeEtc"
      ref="refTypeEtc"
      allow-clear
      placeholder=""
      @keyup="ectInput"
    />
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";

export default {
  components: {
    SelectBox,
  },
  props: {
    propsData: {
      type: [Object, Array],
      default: null,
    },
    items: {
      type: [Object, Array],
      default: null,
    },
    label: {
      type: [String],
      default: "",
    },
  },
  watch: {
    propsData(val) {
      console.log("", val);
      this.type = val.visitType;
      this.typeEtc = val.visitTypeEtc;
    },
  },
  data() {
    return {
      type: "",
      typeEtc: "",
    };
  },
  methods: {
    typeListHandler(e) {
      this.type = e;
      this.typeEtc = "";
      this.finallyEmit();
      if (e === "etc") {
        setTimeout(() => {
          this.$refs.refTypeEtc.focus();
          this.finallyEmit();
        }, 500);
      }
    },
    ectInput(e) {
      console.log("", e.target.value);
      this.typeEtc = e.target.value;
      this.finallyEmit();
    },
    finallyEmit() {
      const d = {
        type: this.type,
        typeEtc: this.typeEtc,
      };
      this.$emit("complete", d);
    },
  },
};
</script>
<style lang="scss">
._box {
  display: flex;
  width: 100%;
}
</style>