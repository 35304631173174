<template>
  <div class="howOftenBox">
    <SelectBox
      label="How Often"
      :value="select"
      :items="howOftenList"
      @changeSelect="updateSelect"
    />
    <SelectBox
      label="Shot"
      selectStyleWidth="width: 130px"
      :value="selectShot"
      :items="shotList"
      @changeSelect="shotUpdateSelect"
    />
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");

export default {
  components: {
    SelectBox,
  },
  props: {
    selectedValue: {
      type: [Object, Array],
      default: null,
    },
  },
  watch: {
    selectedValue(val) {
      if (val === null) return;
      this.select = val.howOften;
      this.selectShot = val.shot;
    },
  },
  data() {
    return {
      select: this.selectedValue === null ? "" : this.selectedValue.howOften,
      selectShot: this.selectedValue === null ? "" : this.selectedValue.shot,
      howOftenList: formatService.howOftenList(),
      shotList: formatService.shotList(),
    };
  },
  methods: {
    updateSelect(e) {
      this.select = e;
      this.selectShot = "";
      this.finallyEmit();
    },
    shotUpdateSelect(e) {
      this.selectShot = e;
      this.finallyEmit();
    },
    finallyEmit() {
      const d = {
        howOften: this.select,
        shot: this.selectShot,
      };
      this.$emit("complete", d);
    },
  },
};
</script>
<style lang="scss">
.howOftenBox {
  display: flex;
  width: 330px;
}
</style>