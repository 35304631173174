<template>
  <SelectBox
    label="아이템타입"
    selectStyleWidth="width: 100%"
    :value="selectedId"
    :items="productTypeItemData"
    :multiple="false"
    @changeSelect="changeSelect"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
export default {
  components: { SelectBox },
  props: {
    selectedTypeId: {
      type: [Number],
    },
    selectedId: {
      type: [Number],
    },
  },
  data() {
    return {
      productTypeItemData: [],
      reqData: {},
    };
  },
  methods: {
    getProductTypeItemData(selectedTypeId) {
      const $h = this.$helper;
      this.productTypeData = [];
      this.reqData.type_id = selectedTypeId;
      this.reqData.item_id = 0;
      this.reqData.endpoint = "productTypeItem";
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        this.footerHide = res.length > 5 ? false : true;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            value: item.item_id,
            text: $h.definedStringI18($h.makeStringToArray(item.productTypeItemName, "@@"), 'kr')
          }));
        }
        this.productTypeItemData = res;
      });
    },
    changeSelect(e) {
      this.$emit("changeSelect", e);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="">
</style>