<template>
  <v-row>
    <v-col class="timeBox">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="startTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="timeBox">
            <v-text-field
              v-model="startTime"
              label="Start"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            />
            <!-- <div class="gap">~</div> -->
          </div>
        </template>
        <v-time-picker
          v-if="menu1"
          v-model="startTime"
          full-width
          use-seconds
          @click:second="
            $refs.menu1.save(startTime);
            finallyEmit();
          "
        />
      </v-menu>
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="endTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="timeBox">
            <v-text-field
              v-model="endTime"
              label="End"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </div>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="endTime"
          full-width
          use-seconds
          @click:second="
            $refs.menu2.save(endTime);
            finallyEmit();
          "
        />
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    propsStartTime: {
      type: [String],
    },
    propsEndTime: {
      type: [String],
    },
  },
  watch: {
    propsStartTime(val) {
      this.startTime = val;
    },
    propsEndTime(val) {
      console.log('propsEndTime', val);
      this.endTime = val;
    }
  },
  data() {
    return {
      startTime: this.propsStartTime,
      endTime: this.propsEndTime,
      menu1: false,
      menu2: false,
    };
  },
  methods: {
    _resEndTime() {
      this.endTime = "";
      console.log('endTime', this.endTime);
      console.log('propsEndTime', this.propsEndTime);
    },
    finallyEmit() {
      if (this.startTime === null) {
        this.$helper.showTostMessage("", "Please select a Start time", "info");
        return;
      }
      if (this.endTime === null) {
        this.$helper.showTostMessage("", "Please select a End time", "info");
        return;
      }
      // console.log("", this.startTime, this.endTime);
      this.$emit("completeTime", this.startTime, this.endTime);
    },
  },
};
</script>
<style lang="scss">
.timeBox {
  display: flex;
  // width: 150px;
}
</style>