<template>
  <v-dialog v-model="visible" max-width="1200px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        Drug Search
      </v-card-title>

      <SearchTopUIComp
        placeholder="Search Drug Name"
        :propsSearchWorld="reqData.searchVal"
        @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete"
      />

      <DataTable
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :perPage="10000"
        :tableKey="'rowSeq'"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="tableColumns"
        :inputData="list"
        @clickRow="clickRow"
      />

      <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
        <v-btn color="primary" depressed large @click="close">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Header from "./comp/Header.vue";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
const formatService = require('@/utils/format.js');
export default {
  components: {
    Header,
    SearchTopUIComp,
    DataTable
  },
  data() {
    return {
      visible: false,
      modalTopPos: { top: '300px' },
      list: [],
      reqData: {
        endpoint: 'drugInfo',
        searchGbn: '',
        searchVal: '',
        useYN: 'Y',
      },
      loading: false,
      loadingText: "Loading list...",
      selectedDrug: null,
      tableColumns: formatService.medicineTableColumns(),
    };
  },
  methods: {
    showModal(topPos) {
      this.modalTopPos = {
        top: topPos + 'px',
      };
      this.visible = true;
      this.selectedCode = [];
      this.reqData.searchVal = '';
      this.list = [];
      setTimeout(() => {
        // this.$refs.refTopUI.$refs.txtSearchWorld.focus();
      }, 500);
    },
    close() {
      this.visible = false;
    },
    // 검색어
    changeSearchWordComplete(e) {
      console.log('', e);

      if (e.length < 3) {
        this.$helper.showTostMessage('bottom-right', 'Please enter at least 3 characters', 'info');
        this.$refs.refTopUI.$refs.txtSearchWorld.focus();
        return;
      }
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    getData() {
      this.list = [];
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        console.log('', resP.data);
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            selected: '',
          }));
        }
        this.list = res;
      });
    },
    clickRow(item) {
      // item.selected = item.selected === 'Y' ? '' : 'Y';
      // if (item.selected === 'Y') {
      //   this.selectedCode.push(item.code);
      // } else {
      //   const idx = this.selectedCode.indexOf(item.code);
      //   if (idx > -1) {
      //     this.selectedCode.splice(idx, 1);
      //   }
      // }
      console.log('selectedDrug', item);
      this.visible = false;
      this.$emit('selectedDrug', item);
    },
    ...mapActions(['getDataQuery']),
  },
};
</script>
<style lang="scss">
.listBox {
  overflow-y: auto;
  height: 400px;
}
</style>