<template>
  <v-row>
    <v-col cols="12">
      <v-icon class="iconBox" @click="feds('phone')" :disabled="true">mdi-phone</v-icon>
      <v-icon class="iconBox" @click="feds('video-plus')" :disabled="true">mdi-video-plus</v-icon>
      <v-icon class="iconBox" @click="feds('email')">mdi-email-edit</v-icon>
      <v-icon @click="feds('sms')">mdi-message-processing</v-icon>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    userData: {
      type: [Object, Array],
    },
  },
  methods: {
    feds(type) {
      console.log('', type);
      
      this.$emit("fedRequest", type);
    },
  },
};
</script>
<style lang="scss">
.iconBox {
  padding-right: 7px;
}
</style>