<template>
  <div class="_box">
    <v-text-field
      ref="refUpdateValue"
      label="Last Update Value"
      type="text"
      v-model="propsData.indicatorValue"
      :rules="[filedRules.required]"
    />
    <SelectBox
      selectStyleWidth="width: 100px"
      ref="refUnit"
      label="Unit"
      :value="indicatorUnit"
      :items="indicatorUnitItems"
      @changeSelect="updateSelectIndicatorUnit"
    />
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBox,
  },
  props: {
    propsData: {
      type: [Object, Array],
      default: null,
    },
  },
  watch: {
    propsData(val) {
      if (val === null) return;
      this.indicatorUnit = val.indicatorUnit;
    },
  },
  data() {
    return {
      indicatorUnit: this.propsData.indicatorUnit,
      indicatorUnitItems: formatService.heightList(),
      filedRules: rulesService.filedRules()
    };
  },
  methods: {
    _updateUnit(e) { 
      console.log('onChangeIndicator', e);
      if (e === 'height') this.indicatorUnitItems = formatService.heightList();
      if (e === 'weight') this.indicatorUnitItems = formatService.weightList();
      if (e === 'bmi') {
        this.indicatorUnitItems = formatService.bmiUnitList();
        this.updateSelectIndicatorUnit('kg/m²');
      }
      if (e === 'bp_systolic') {
        this.indicatorUnitItems = formatService.bpSystolicUnitList();
        this.updateSelectIndicatorUnit('mmHg');
      }
      if (e === 'bp_diastolic') {
        this.indicatorUnitItems = formatService.bpDiastolicUnitList();
        this.updateSelectIndicatorUnit('mmHg');
      }
      if (e === 'bp_pulseRate') {
        this.indicatorUnitItems = formatService.bpPulseRateUnitList();
        this.updateSelectIndicatorUnit('bpm');
      }
      if (e === 'hbA1c') {
        this.indicatorUnitItems = formatService.hbA1cUnitList();
        this.updateSelectIndicatorUnit('%');
      }
    },
    updateSelectIndicatorUnit(e) {
      this.indicatorUnit = e;
      this.$emit("updateSelect", e)
    }
  },
};
</script>
<style lang="scss">
._box {
  display: flex;
  width: 100%;
}
</style>