<template>
  <div id="drugNameBox" class="drugNameBox">
    <v-text-field
      type="text"
      ref="refSelect"
      v-model="selectedDrugName"
      placeholder="drug name"
      :disabled="true"
    />
    <v-btn color="primary" depressed small @click="clickSearch">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <DialogDrugList ref="refSearchList" @selectedDrug="selectedDrugHandler" />
  </div>
</template>
<script>
import DialogDrugList from '@/components/commonV2/Dialog/DialogDrugList.vue';
export default {
  components: {
    DialogDrugList,
  },
  props: {
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      selectedDrugName: this.data === undefined ? '' : this.data.drugName,
    };
  },
  watch: {
    data(val) {
      this.selectedDrugName = this.data === undefined ? '' : val.drugName;
    },
  },
  methods: {
    clickSearch() {
      let drugNameBox = document.getElementById('drugNameBox');
      let topPos = drugNameBox.getBoundingClientRect().top + 35;
      console.log('drugNameBox', topPos);

      this.$refs.refSearchList.showModal(topPos);
    },
    selectedDrugHandler(e) {
      this.selectedDrugName = e.drugName;
      this.$emit('complete', e);
    },
  },
};
</script>
<style lang="scss">
.drugNameBox {
  display: flex;
  width: 100%;
}
</style>