<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ sendType.toUpperCase() }} </v-card-title>
      <v-card-text>
        <v-row class="mb-10">
          <v-col>
            <v-textarea
              ref="refDescription"
              outlined
              label=""
              counter="1000"
              maxLength="1000"
              v-model="description"
              :placeholder="$t('dialog.careNote.description')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
        <v-btn color="primary" outlined large @click="close"> Cancel </v-btn>
        <v-btn color="primary" depressed large @click="Send"> Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Header from "./comp/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      visible: false,
      sendType: "",
      description: "",
      userData: null,
    };
  },
  methods: {
    open(userData, sendType) {
      this.visible = true;
      this.userData = userData;
      this.sendType = sendType;
    },
    Send() {},
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss">
.height {
  min-height: 250px !important;
}
</style>