<template>
  <div class="btnPos">
    <div class="vBtn">
      <v-btn color="primary" depressed small @click="clickBtn('CareNote')">
        Care Note
      </v-btn>
    </div>
    <div class="vBtn">
      <v-btn color="primary" depressed small @click="clickBtn('UserInfo')">
        UserInfo Edit
      </v-btn>
    </div>
    <div class="vBtn">
      <v-btn color="secondary" depressed small @click="clickBtn('Survey')"
        >Survey</v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    clickBtn(type) {
      this.$emit("clickEvent", type);
    },
  },
};
</script>
<style lang="scss">
.btnPos {
  display: flex;
  align-items: right;
  // justify-content: right;
  .vBtn {
    margin: 5px;
  }
}
</style>