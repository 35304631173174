<template>
  <v-combobox
    item-text="drugName"
    item-value="drugName"
    placeholder="Enter at least 3 digits."
    small-chips
    deletable-chips
    label="Drug Name"
    :value="value && value.drugName ? value.drugName.split(',') : []"
    :multiple="true"
    :items="itemsDrug"
    @keyup="drugCheck"
    @change="updateSelect"
    @blur="updateSelect"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
export default {
  components: {SelectBox},
  props: {
    value: {
      type: Object,
      default: null,
    },
    select: {
      type: String
    }
  },
  data() {
    return {
      itemsDrug: [],
      itemsHistory: [],
      reqData: {
        endpoint: 'drugInfo',
        searchGbn: '',
        searchVal: '',
        useYN: 'Y',
      },
      selectedDrug: null,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (val === null) return;
        if (val.drugName) {
          val.drugName = val.drugName.replace(/\s/g, "");
          const temp = val.drugName.replace(/\s/g, "").split(",");
          this.itemsHistory = new Set([...temp, ...this.itemsHistory]);
        }
      },
    },
  },
  mounted() {
    if (this.value === null) return;
    try {
      this.value.drugName = this.value.drugName.replace(/\s/g, "");
      const temp = this.value.drugName.replace(/\s/g, "").split(",");
      this.itemsHistory = new Set([...temp]);
    } catch (e) {}
  },
  methods: {
    getData() {
      this.list = [];
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        // console.log("", resP.data);
        let a = resP.data;
        this.itemsDrug = [...a];
        console.log("this.itemsDrug", this.itemsDrug);
        this.itemsHistory = new Set([
          ...a.map((item) => item.drugName),
          ...this.itemsHistory,
        ]);
      });
    },
    async drugCheck(e) {
      const val = e.target.value;
      this.reqData.searchVal = val;
      if (val.length > 2) {
        this.getData()
      }
    },
    updateSelect(val) {
      // console.log('check', val.target);
      if (val.target) {
      } else {
        // 엔터쳐서 입력하는 경우와 클릭으로 선택하는 케이스.
        const lastVal = val[val.length - 1]
          ? val[val.length - 1].drugName
          : undefined;
        // 선택하는 경우 (선택해제도 포함)
        if (lastVal) {
          const index = [...this.itemsHistory].findIndex((e) => e === lastVal);
          // itemsHistory : 지금까지 검색했거나 선택했던 모든 코드들 세트. 이중에서 없으면 무효한 값이라고 판단해서 val에서 제거
          if (index === -1) {
            val = val.slice(0, -1);
          }
        }
        //엔터치는 경우
        else {
          const index = [...this.itemsHistory].findIndex(
            (e) => e === val[val.length - 1]
          );

          if (index === -1) {
            val = val.slice(0, -1);
          }
        }
        let data = val.map((item) => {
          //기존 값들 + 마지막에는 선택한 객체
          const x = item.drugName ? item : item;
          return x;
        });
        data = data.join(",");
        this.value.drugName = data;
        this.$emit("updateValue", { type: "drugName", data: data });
        this.$forceUpdate();
      }
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.icdCodeBox {
  display: flex;
  width: 100%;
}
</style>