<template>
  <div class="d-flex align-center flex-column pa-6">
    <v-btn-toggle v-model="toggle" variant="outlined" divided>
      <v-btn @click="clickBtn(1)">1M</v-btn>
      <v-btn @click="clickBtn(3)">3M</v-btn>
      <v-btn @click="clickBtn(6)">6M</v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  data() {
    return {
      toggle: null,
    };
  },
  methods: {
    clickBtn(e) {
      console.log("e", e);
    },
  },
};
</script>
<style lang="">
</style>