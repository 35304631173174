<template>
  <div class="dateAndTimeBox">
    <DatePickerWithInputText
      :label="propsPlaceholder"
      :value="date"
      @onChangeDate="onChangeDateDatePicker"
    />
    <TimePicker 
      ref="refTimePicker"
      :propsStartTime="startTime" 
      :propsEndTime="endTime"
      @completeTime="completeTime" />
  </div>
</template>

<script>
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import TimePicker from "@/components/commonV2/ui/TimePicker.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { DatePickerWithInputText, TimePicker },
  props: {
    propsData: {
      type: [Object, Array],
    },
    propsPlaceholder: {
      type: [String],
      default: "Select Date",
    },
  },
  watch: {
    propsData(val) {
      if (val === undefined || val === null || val === "") return;
      this.date = val.date;
      this.startTime = val.startTime;
      this.endTime = val.endTime;
    },
  },
  data() {
    return {
      date: this.propsData === undefined ? "" : this.propsData.date,
      startTime: this.propsData === undefined ? "" : this.propsData.startTime,
      endTime: this.propsData === undefined ? "" : this.propsData.endTime,
    };
  },
  methods: {
    onChangeDateDatePicker(e) {
      if (e === "" || e === null) return;
      this.date = e;
      this.calTimeDiff();
    },
    completeTime(startTime, endTime) {
      console.log("completeTime", startTime, endTime);
      this.startTime = startTime;
      this.endTime = endTime;
      if (this.date === null || this.date === "") {
        this.$helper.showTostMessage("", "Please select a Date", "info");
        return;
      }
      this.calTimeDiff();
    },
    // 2시간비교
    calTimeDiff() {
      if (this.date === null || this.date === "") {
        this.$helper.showTostMessage("", "Please select a Date", "info");
        return;
      }
      if (this.startTime === null || this.startTime === "") {
        this.$helper.showTostMessage("", "Please select a Start Time", "info");
        return;
      }
      if (this.endTime === null || this.endTime === "") {
        this.$helper.showTostMessage("", "Please select a End Time", "info");
        return;
      }
      let startDate = this.$helper.changeStringToDate(this.date + " " + this.startTime);
      let endDate = this.$helper.changeStringToDate(this.date + " " + this.endTime);
      console.log("", startDate, endDate);
      // return;
      const dueTime = this.$moment.duration(endDate.diff(startDate)).asSeconds();
      console.log("dueTime : ", dueTime);
      if (dueTime <= 0) {
        this.$helper.showTostMessage("", "End time must be greater than start time", "error");
        setTimeout(() => {
          this.endTime = "";
          this.$refs.refTimePicker._resEndTime();
        }, 500);
        return;
      }
      this.finallyEmit(dueTime);
    },
    finallyEmit(dueTime) {
      const DateTime = {
        date: this.$helper.changeDateToString(this.date),
        startTime: this.startTime,
        endTime: this.endTime,
        dueTime: dueTime
      };
      console.log('DateTime', DateTime);
      this.$emit("complete", DateTime);
    },
  },
};
</script>

<style lang="scss">
.dateAndTimeBox {
  display: flex;
  width: 400px;
}
</style>