<template>
  <v-row>
    <div class="timeFont">{{ timerView() }}</div>
    <div>
      <div v-if="!timer.isPlaying">
        <v-icon @click="resume">mdi-play-box-edit-outline</v-icon>
      </div>
      <div v-else>
        <v-icon @click="pause">mdi-pause-circle-outline</v-icon>
        <v-icon @click="stop">mdi-stop-circle-outline</v-icon>
      </div>
    </div>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    userData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      timer: {
        sDate: null,
        eDate: null,
        startTime: "",
        endTime: "",
        tik: 0,
        pointer: null,
        isPlaying: false,
      },
      idleTimer: {
        sDate: null,
        eDate: null,
        startTime: "",
        endTime: "",
        tik: 0,
        pointer: null,
        isPlaying: false,
      },
    };
  },
  watch: {
    // vuex
    careNoteTimer(val, oldVal) {
      console.log("careNoteTimerState", this.careNoteTimer);
      if (this.careNoteTimer === "play") this.startNewTimer();
      if (this.careNoteTimer === "resume") this.resume();
      if (this.careNoteTimer === "pause") this.pause();
      if (this.careNoteTimer === "stop") this.stop();
    },
  },
  computed: {
    ...mapState(["me", "careNoteTimer"]),
  },
  methods: {
    startNewTimer() {
      // 초기화
      // console.log('startNewTimer');
      this.clearTimer();
      this.timer.startTime = this.$moment().format("HH:mm:ss");
      this.setTimer("paly");
      // console.log('careNoteTimerState', this.careNoteTimer);

      this.timer.sDate = this.$moment();
      this.timer.isPlaying = true;
      this.timer.pointer = setInterval(() => {
        const currentTime = this.$moment();
        this.timer.tik = Math.floor(
          this.$moment.duration(currentTime.diff(this.timer.sDate)).asSeconds()
        );
        this.timer.eDate = this.$moment();
        this.timer.endTime = this.$moment().format("HH:mm:ss");
        // console.log('', this.timer);
      }, 1000);
    },
    clearTimer() {
      this.setCareNoteTime(this.timer);
      clearInterval(this.timer.pointer);
      this.timer.isPlaying = false;
      clearInterval();
    },
    pause() {
      // 버그있음 -> 퍼지 안됨
      this.clearTimer();
      this.setTimer("pause");
    },
    resume() {
      this.setTimer("resume");
      this.timer.isPlaying = true;
      const sData = this.$moment();
      const currentTik = this.timer.tik;
      this.timer.pointer = setInterval(() => {
        const currentTime = this.$moment();
        this.timer.tik =
          currentTik +
          Math.floor(
            this.$moment.duration(currentTime.diff(sData)).asSeconds()
          );
        this.timer.eDate = this.$moment();
        this.timer.endTime = this.$moment().format("hh:mm:ss");
      }, 1000);
    },
    stop() {
      this.clearTimer();
      // this.timer.eDate = new Date();
      // 관리자일때 무시~
      // if (this.$store.getters.isAdminGroup) {
      //   this.noSave();
      //   return;
      // }
      // console.log('', this.timer);
      this.setTimer("stop");
      this.$emit("openCareNodeForTimer");
    },
    timerView() {
      let h, m, s;
      h = parseInt(this.timer.tik / (60 * 60));
      m = parseInt(this.timer.tik / 60) - h * 60;
      s = this.timer.tik % 60;
      // console.log('timerView : ', this.timer);

      return (this.time = `${String(h).padStart(2, "0")}:${String(m).padStart(2,"0")}:${String(s).padStart(2, "0")}`);
    },
    ...mapActions(["setCareNoteTime", "setTimer"]),
  },
};
</script>
<style lang="scss">
.btnPause {
  width: 40px;
}
.timeFont {
  font-size: 18px;
  padding: 5px 15px 5px 15px;
}
</style>