<template>
  <v-row class="controlUiPos" v-if="patientData">
    <v-col cols="12" md="3">
      <UserInfo :patientData="patientData" />
    </v-col>
    <v-col cols="12" md="9">
      <v-row>
        <v-col cols="12" md="3">
          <ComUI @fedRequest="fedRequest" />
        </v-col>
        <v-col cols="12" md="4">
          <TimerUI_V2
            ref="refTimer"
            @openCareNodeForTimer="openCareNodeForTimer"
          />
        </v-col>
        <v-col cols="12" md="5">
          <Btn @clickEvent="clickEvent" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import UserInfo from "./comp/UserInfo.vue";
import ComUI from "./comp/ComUI.vue";
import Btn from "./comp/Btn.vue";
import TimerUI_V2 from "./comp/TimerUI_V2.0.vue";
export default {
  components: {
    UserInfo,
    ComUI,
    Btn,
    TimerUI_V2,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      isPlay: false,
    };
  },
  methods: {
    clickEvent(type) {
      // console.log("type", type);
      this.$emit(`open${type}`);
    },
    fedRequest(option) {
      this.$emit("fedRequest", option);
    },
    openUserInfoEdit() {
      this.$emit("openUserInfoEdit");
    },
    openCareNodeForTimer() {
      this.$emit("openCareNodeForTimer");
    },
  },
};
</script>
<style lang="scss">
.controlUiPos {
  padding-top: 10px;
}
.pos {
  display: flex;
  flex-grow: 2;
  padding-left: 15px;
  height: 70px;
  .userInfo {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 30%;
    min-width: 500px;
    .name {
      padding-left: 15px;
      color: blue;
    }
    .age {
      color: blueviolet;
    }
    .service {
      color: red;
    }
  }
  .btnPos {
    width: 70%;
    .comUI {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .timerUI {
      min-width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>