<template>
  <div class="selectBox">
    <v-row>
      <!--  :propsSelectedId="selectedProductTypeId"  -->
      <SelectBoxProductType 
        ref="refSelectBoxProductType" 
        @changeSelect="productTypeChangeSelect" />
    </v-row>
    <v-row>
      <SelectBoxProductTypeItem 
        ref="refSelectBoxProductTypeItem" 
        :selectedTypeId="selectedProductTypeId" 
        :selectedId="selectedProductTypeItemId"
        @changeSelect="productTypeItemChangeSelect" />
    </v-row>
  </div>
</template>
<script>
import SelectBoxProductType from "@/components/commonV2/ui/SelectBoxProductType.vue";
import SelectBoxProductTypeItem from "@/components/commonV2/ui/SelectBoxProductTypeItem.vue";
export default {
  components: {
    SelectBoxProductType,
    SelectBoxProductTypeItem
  },
  data() {
    return {
      selectedProductTypeId: 0, 
      selectedProductTypeItemId: 0
    }
  },
  methods: {
    _initData(type_id, item_id) {
      this.selectedProductTypeId = type_id;
      this.selectedProductTypeItemId = item_id;
      this.$refs.refSelectBoxProductType.getProductTypeData("Y", type_id);
      if(this.selectedProductTypeItemId > 0) {
        setTimeout(() => {
          this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(type_id)
        }, 300);
      }
    },
    productTypeChangeSelect(e) {
      console.log('e', e);
      this.selectedProductTypeId = e;
      this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(e);
      this.$emit("typeSelectedComplete", this.selectedProductTypeId, 0);
    },
    productTypeItemChangeSelect(e) {
      this.$emit("itemSelectedComplete", this.selectedProductTypeId, e);
    },
  },
}
</script>
<style lang="scss">
.selectBox {
  width: 100%;
}
</style>