<template>
  <SelectBox
    label="상품타입"
    selectStyleWidth="width: 100%"
    :value="selectedId"
    :items="productTypeData"
    :multiple="false"
    @changeSelect="changeSelect"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: { SelectBox },
  props: {
    // propsSelectedId: {
    //   type: [Number],
    // },
  },
  data() {
    return {
      selectedId: -1,
      productTypeData: [],
      reqData: modelService.productTypeItemModel(),
    };
  },
  // watch: {
  //   propsSelectedId: {
  //     handler(val, oldVal) {
  //       this.selectedId = val;
  //     },
  //     immediate: true // 컴포넌트가 생성되자마자 즉시 실행
  //   }
  // },
  methods: {
    getProductTypeData(isCommon, type_id) {
      this.selectedId = type_id;
      const $h = this.$helper;
      this.productTypeData = [];
      this.reqData.isCommon = isCommon;
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            value: item.type_id,
            text: $h.definedStringI18($h.makeStringToArray(item.productTypeName, "@@"), 'kr') //item.productTypeName,
          }));
        }
        this.productTypeData = res;
      });
    },
    changeSelect(e) {
      this.$emit("changeSelect", e);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="">
</style>