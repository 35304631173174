<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-avatar size="40px">
          <v-icon class="iconStyle" v-if="patientData.isPhoto === 'N'">
            mdi-account-circle
          </v-icon>
          <v-img v-else alt="Avatar" :src="patientData.userPhotoImg" />
        </v-avatar>
        <div class="nameBox">
          {{ patientData.gender }}/{{ patientData.age }}
          <span class="service">{{ patientData.serviceType }}
            ({{ patientData.diagnosisType }})
          </span>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
};
</script>
<style lang="scss">
.iconStyle {
  font-size: 40px !important;
  color: rgb(210, 210, 210) !important;
}
.nameBox {
  padding: 0 10px 0 10px;
}
</style>